<template>
    <b-overlay :show="is_busy" rounded="sm">
        <b-container fluid>
            <b-row class="mb-2">
                <b-col md="6">
                    <h2 v-show="!editMode"><strong>Enroll Student</strong></h2>
                    <h2 v-show="editMode"><strong>Edit Student</strong></h2>
                </b-col>
            </b-row>
          
            <div class="alert alert-danger p-2" v-if="Object.keys(errors).length > 0">
                <ul>
                    <li v-for="error in errors" v-bind:key="error">
                        {{ error[0] }}
                    </li>
                </ul>
            </div>
            <div class="card">
                <form-wizard @on-complete="editMode ? updateStudent() : createStudent()">
                    <tab-content title="Personal Data" icon="fa fa-user">
                        <b-card no-body>
                            <b-card-body>
                                <b-row>
                                <div class="col-md-4">
                                    <div class="form-group">
                                    <label>Surname</label>
                                    <input v-model="form.lastname" type="text" class="form-control" required>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                    <label>Firstname</label>
                                    <input v-model="form.firstname" type="text" class="form-control" required>
                                    </div>
                                </div>
                                
                                <div class="col-md-4">
                                    <div class="form-group">
                                    <label>Middlename</label>
                                    <input v-model="form.middlename" type="text" class="form-control">
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <b-form-group class="">
                                    <label>Date of Birth</label>
                                    <input v-model="form.dob" type="date" class="form-control" >
                                    </b-form-group>
                                </div>

                                <div class="col-md-3">
                                    <b-form-group class="">
                                    <label>Gender</label>
                                    <b-form-select v-model="form.gender" :options="gender" ></b-form-select>
                                    </b-form-group>
                                </div>

                                <div class="col-md-3">
                                    <b-form-group class="">
                                    <label>Religion</label>
                                    <b-form-select v-model="form.religion" :options="religion" ></b-form-select>
                                    </b-form-group>
                                </div>

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label>Featured Image</label>
                                        <input type="file" accept="image/*" @change="uploadImage" name="image" class="form-control">
                                    </div>

                                    <div class="my-2" v-if="student.image">
                                        <img :src="form.image" class="img-fluid" style="height:50px">
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                    <label>Current Address</label>
                                    <input v-model="form.current_address" type="text" class="form-control" >
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                    <label>Parmanent Address</label>
                                    <input v-model="form.parmanent_address" type="text" class="form-control">
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                    <label>Country</label>
                                    <input v-model="form.country" type="text" class="form-control" >
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <b-form-group class="">
                                    <label>State of Origin</label>
                                    <select v-model="form.state" class="form-control" @change="onChange($event)">
                                        <option value=null> -- Select State -- </option>
                                        <option v-for="option in states" :key="option.id" v-bind:value="option.id">
                                        {{ option.title }}
                                        </option>
                                    </select>
                                    </b-form-group>
                                </div>

                                <div class="col-md-4">
                                    <b-form-group class="">
                                    <label>LGA of Origin</label>
                                    <select v-model="form.city" class="form-control">
                                        <option value=null> -- Select LGA -- </option>
                                        <option v-for="option in lgas" v-bind:value="option.id" v-bind:key="option.id">
                                        {{ option.name }}
                                        </option>
                                    </select>
                                    </b-form-group>
                                </div>                  
                                </b-row>
                            </b-card-body>
                        </b-card>
                    </tab-content>

                    <tab-content title="Parents/Guardian" icon="fas fa-user-friends">
                        <b-card no-body>
                            <b-card-body>
                                <b-row>
                                    <div class="col-md-6">
                                        <b-form-group class="">
                                            <select v-model="form.type_of_parent" class="form-control" >
                                                <option value=null> -- Select Guardian-- </option>
                                                <option value="archive">Select from Archive</option>
                                                <option value="new">Add New Guardian</option>
                                            </select>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-6">
                                        <b-form-group class="">
                                            <b-form-select v-model="form.g_relationship" :options="g_relate" ></b-form-select>
                                        </b-form-group>
                                    </div>
                                </b-row>

                                <div v-if="form.type_of_parent=='new'">
                                    <b-row>
                                    <div class="col-md-4">
                                        <b-form-group class="">
                                        <label>Title</label>
                                        <b-form-select v-model="form.g_title" :options="g_title"></b-form-select>
                                        </b-form-group>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="form-group">
                                        <label>Guardian Name</label>
                                        <input v-model="form.g_name" type="text" class="form-control" >
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="form-group">
                                        <label>Phone Number</label>
                                        <input v-model="form.g_phone" type="tel" class="form-control" >
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="form-group">
                                        <label>Email</label>
                                        <input v-model="form.g_email" type="email" class="form-control">
                                        </div>
                                    </div>
                                    
                                    <!--<div class="col-md-4">
                                        <div class="form-group">
                                        <label>Password</label>
                                        <input v-model="form.g_password" type="password" class="form-control">
                                        </div>
                                    </div>-->

                                    <div class="col-md-4">
                                        <div class="form-group">
                                        <label>Occupation</label>
                                        <input v-model="form.g_occupation" type="text" class="form-control" >
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="form-group">
                                        <label>Contact Address</label>
                                        <input v-model="form.g_address" type="text" class="form-control" >
                                        </div>
                                    </div>
                                    
                                    </b-row>
                                </div>

                                <div v-else-if="form.type_of_parent=='archive'">
                                    <b-row>
                                    <div class="col-md-12">
                                        <b-form-group class="">
                                        <label>Select Guardian</label>
                                        <select v-model="form.guardian" class="form-control" >
                                            <option value=null> -- Select Guardian-- </option>
                                            <option v-for="option in parents" :key="option.id" v-bind:value="option.id">
                                            {{ option.title | capitalize }} {{ option.name }}
                                            </option>
                                        </select>
                                        </b-form-group>
                                    </div>
                                    </b-row>
                                </div>
                            </b-card-body>
                        </b-card>
                    </tab-content>

                    <tab-content title="Admissions/Enrollment" icon="fa fa-book">
                        <b-card no-body>
                            <b-card-body>
                            <b-row>
                                <div class="col-md-4">
                                <div class="form-group">
                                    <label>Reg. Number</label>
                                    <input v-model="form.roll" type="text" class="form-control">
                                </div>
                                </div>

                                <div class="col-md-4">
                                <b-form-group class="">
                                    <label>Date of Admission</label>
                                    <input v-model="form.doa" type="date" class="form-control" >
                                </b-form-group>
                                </div>

                                <div class="col-md-4">
                                <b-form-group class="">
                                    <label>Assign Class</label>
                                    <select v-model="form.class" class="form-control" required>
                                    <option value=null> -- Select Class-- </option>
                                    <option v-for="option in classes" :key="option.id" v-bind:value="option.id">
                                        {{ option.name }}{{ option.form }}
                                    </option>
                                    </select>
                                </b-form-group>
                                </div>
                            </b-row>
                            </b-card-body>
                        </b-card>
                    </tab-content>

                    <tab-content title="Medical Records" icon="fas fa-people-carry">
                        <b-card no-body>
                            <b-card-body>
                            <b-row>
                                <div class="col-md-4">
                                <b-form-group class="">
                                    <label>Blood Group</label>
                                    <b-form-select v-model="form.blood_group" :options="bg"></b-form-select>
                                </b-form-group>
                                </div>

                                <div class="col-md-4">
                                <b-form-group class="">
                                    <label>Genotype</label>
                                    <b-form-select v-model="form.genotype" :options="genotype"></b-form-select>
                                </b-form-group>
                                </div>

                                <div class="col-md-4">
                                <b-form-group class="">
                                    <label>Asthmatic</label>
                                    <b-form-select v-model="form.asthmatic" :options="asthmatic"></b-form-select>
                                </b-form-group>
                                </div>

                                <div class="col-md-6">
                                <b-form-group class="">
                                    <label>Any Eye Issues</label>
                                    <b-form-select v-model="form.eye" :options="eye"></b-form-select>
                                </b-form-group>
                                </div>

                                <div class="col-md-6">
                                <b-form-group class="">
                                    <label>Any Disability</label>
                                    <b-form-select v-model="form.disability" :options="disability"></b-form-select>
                                </b-form-group>
                                </div>

                                <div class="col-md-6" v-if="form.eye=='Yes'">
                                <div class="form-group">
                                    <label>Briefly explain the eye issue</label>
                                    <textarea v-model="form.eye_issue" class="form-control"></textarea>
                                </div>
                                </div>

                                <div class="col-md-6" v-if="form.disability=='Yes'">
                                <div class="form-group">
                                    <label>Briefly explain the disability</label>
                                    <textarea v-model="form.disability_issue" class="form-control"></textarea>
                                </div>
                                </div>

                                <div class="col-md-12" v-if="form.disability=='Yes'">
                                <div class="form-group">
                                    <label>Other Medical Situations</label>
                                    <textarea v-model="form.other_med" class="form-control"></textarea>
                                </div>
                                </div>
                            </b-row>
                            </b-card-body>
                        </b-card>
                    </tab-content>
                    
                    <tab-content title="Credentials" icon="fa fa-check">
                        <b-card no-body>
                            <b-card-body>
                                <div class="row">
                                    <b-row>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                        <label>Birth Certificate</label>
                                        <input type="file" accept="image/*" @change="uploadImageBC" name="birth_certificate" class="form-control">
                                        </div>

                                        <div class="my-2" v-if="student.birth_certificate">
                                        <img :src="form.birth_certificate" class="img-fluid" style="height:50px">
                                        </div>

                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                        <label>Medical Certificate</label>
                                        <input type="file" accept="image/*" @change="uploadImageMC" name="medical_certificate" class="form-control">
                                        </div>

                                        <div class="my-2" v-if="student.medical_certificate">
                                        <img :src="form.medical_certificate" class="img-fluid" style="height:50px">
                                        </div>
                                    </div>

                                    <div class="col-md-3">
                                        <div class="form-group">
                                        <label>Acceptance Form</label>
                                        <input type="file" accept="image/*" @change="uploadImageAF" name="acceptance_form" class="form-control">
                                        </div>
                                        <div class="my-2" v-if="student.acceptance_form">
                                        <img :src="form.acceptance_form" class="img-fluid" style="height:50px">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                        <label>LGA Identification</label>
                                        <input type="file" accept="image/*" @change="uploadImageLGI" name="lgi" class="form-control">
                                        </div>

                                        <div class="my-2" v-if="student.lgi">
                                        <img :src="form.lgi" class="img-fluid" style="height:50px">
                                        </div>
                                    </div>
                                    </b-row>
                                </div>
                            </b-card-body>
                        </b-card>
                    </tab-content>
                </form-wizard> 
            </div>
        </b-container>
    </b-overlay>
</template>

<script>
    import axios from 'axios';
    import { Form } from 'vform';
    import moment from 'moment';

    export default {
        data() {
            return {
                editMode: false,
                gender: [
                { value: null, text: 'Select Gender' },
                { value: 'male', text: 'Male' },
                { value: 'female', text: 'Female' },
                ],

                asthmatic: [
                { value: null, text: 'Select' },
                { value: 'Yes', text: 'Yes' },
                { value: 'No', text: 'No' },
                ],

                eye: [
                { value: null, text: 'Select' },
                { value: 'Yes', text: 'Yes' },
                { value: 'No', text: 'No' },
                ],

                disability: [
                { value: null, text: 'Select' },
                { value: 'Yes', text: 'Yes' },
                { value: 'No', text: 'No' },
                ],

                genotype: [
                { value: null, text: 'Select Genotype' },
                { value: 'AA', text: 'AA' },
                { value: 'AS', text: 'AS' },
                { value: 'SS', text: 'SS' },
                { value: 'AC', text: 'AC' },
                { value: 'SC', text: 'SC' },
                ],

                bg: [
                { value: null, text: 'Select Blood Group' },
                { value: 'A+', text: 'A+' },
                { value: 'A-', text: 'A-' },
                { value: 'B+', text: 'B+' },
                { value: 'B-', text: 'B-' },
                { value: 'AB+', text: 'AB+' },
                { value: 'AB-', text: 'AB-' },
                { value: 'O+', text: 'O+' },
                { value: 'O-', text: 'O-' },
                ],

                g_title: [
                { value: null, text: 'Select Title' },
                { value: 'mr.', text: 'Mr.' },
                { value: 'mrs.', text: 'Mrs.' },
                { value: 'miss', text: 'Miss.' },
                { value: 'dr.', text: 'Dr.' },
                { value: 'barr.', text: 'Barr.' },
                { value: 'prof.', text: 'Prof.' },
                ],

                g_relate: [
                { value: null, text: 'Select Relationship to Student' },
                { value: 'father', text: 'Father' },
                { value: 'mother', text: 'Mother' },
                { value: 'uncle.', text: 'Uncle' },
                { value: 'Aunty', text: 'Aunt' },
                { value: 'guardian', text: 'Guardian' },
                ],

                religion: [
                    { value: null, text: 'Select Religion' },
                    { value: 'Christianity', text: 'Christianity' },
                    { value: 'Muslim', text: 'Muslim' },
                    { value: 'African Tradition Religion', text: 'African Traditional Religion' },
                ],
                errors: {},
                parents: {},
                subjects: {},
                classes: {},
                states: {},
                lgas: {},
                student: '',
                form: new Form({
                    id: '',
                    firstname: '',
                    lastname: '',
                    middlename: '',
                    roll: '',
                    password: '',
                    gender: null,
                    religion: null,
                    dob: '',
                    parmanent_address: '',
                    current_address: '',
                    country: 'Nigeria',
                    state: null,
                    city: null,
                    type_of_parent: null,
                    g_title: null,
                    g_name: '',
                    g_email: '',
                    g_phone: '',
                    g_password: '',
                    g_address: '',
                    g_occupation: '',
                    guardian: null,
                    g_relationship: null,
                    doa: '',
                    class: null,
                    image: '',
                    blood_group: null,
                    asthmatic: null,
                    genotype: null,
                    eye: null,
                    disability: null,
                    eye_issue: '',
                    disability_issue: '',
                    other_med: '',
                    birth_certificate: '',
                    medical_certificate: '',
                    acceptance_form: '',
                    lgi: '',
                }),

                is_busy: false,
            }
        },

        created() {
            this.roleHelper(['1', '5']);
            this.load();
        },

        methods: {
            load(){
                let student_id = this.$route.params.student_id;
                if (this.is_busy) return;
                this.is_busy = true;

                let url = '/loaddata';
                if(student_id) {
                    this.editMode = true;
                    url = '/loaddata/' + student_id;
                }
                axios.get(url) 
                .then(res => {
                    this.states = res.data.data.states;
                    this.subjects = res.data.data.subjects;
                    this.parents = res.data.data.parents;
                    this.classes = res.data.data.classes;
                    if(student_id) {
                        this.student = res.data.data.student;
                        this.form = res.data.data.student;
                        this.form.class = this.student.get_class;
                        this.form.doa = moment(this.student.doa).format("YYYY-MM-DD");
                        this.form.dob = moment(this.student.dob).format("YYYY-MM-DD");

                        this.form.type_of_parent = 'archive';

                        if(this.form.state)
                        axios.get('/loadLGA/'+ this.form.state) 
                        .then(({data}) => {
                            this.lgas = data;
                        })
                    }
                })
                .catch(() => {
                    Swal.fire(
                        "Failed!",
                        "Ops, Something went wrong, try again.",
                        "warning"
                    );
                })
                .finally(() => {
                    this.is_busy = false;
                });
            },

            uploadImage(e){
                let file = e.target.files[0];
                let reader = new FileReader();
                if(file['size'] < 2388608){
                reader.onloadend = (file) => {
                    this.form.image = reader.result;
                }
                reader.readAsDataURL(file);
                }else{
                Swal("Failed!", "Oops, You are uploading a large file, try again. Upload file less that 8MB", "Warning")
                }
            },

            uploadImageMC(e){
                let file = e.target.files[0];
                let reader = new FileReader();
                if(file['size'] < 2388608){
                reader.onloadend = (file) => {
                    this.form.medical_certificate = reader.result;
                }
                reader.readAsDataURL(file);
                }else{
                Swal("Failed!", "Oops, You are uploading a large file, try again. Upload file less that 8MB", "Warning")
                }
            },

            uploadImageBC(e){
                let file = e.target.files[0];
                let reader = new FileReader();
                if(file['size'] < 2388608){
                reader.onloadend = (file) => {
                    this.form.birth_certificate = reader.result;
                }
                reader.readAsDataURL(file);
                }else{
                Swal("Failed!", "Oops, You are uploading a large file, try again. Upload file less that 8MB", "Warning")
                }
            },

            uploadImageLGI(e){
                let file = e.target.files[0];
                let reader = new FileReader();
                if(file['size'] < 2388608){
                reader.onloadend = (file) => {
                    this.form.lgi = reader.result;
                }
                reader.readAsDataURL(file);
                }else{
                Swal("Failed!", "Oops, You are uploading a large file, try again. Upload file less that 8MB", "Warning")
                }
            },

            uploadImageAF(e){
                let file = e.target.files[0];
                let reader = new FileReader();
                if(file['size'] < 2388608){
                reader.onloadend = (file) => {
                    this.form.acceptance_form = reader.result;
                }
                reader.readAsDataURL(file);
                }else{
                Swal("Failed!", "Oops, You are uploading a large file, try again. Upload file less that 8MB", "Warning")
                }
            },

            onChange(event) {
                let id = event.target.value;
                axios.get('loadLGA/'+id) 
                .then(({data}) => {
                this.lgas = data;
                })
            },

            createStudent(){
                if(this.is_busy) return;
                this.is_busy = true;
                axios.post('/students', this.form)
                .then(res => {
                    Swal.fire(
                        'Created!',
                        'Student Created Successfully.',
                        'success'
                    )
                    //console.log(data);
                    this.$router.push({path: '/administration/student/'+ res.data.data.id });
                })
                .catch((err) => {
                    this.errors = err.response.data.errors;
                })
                .finally(() => {
                    this.is_busy = false;
                });      
            },

            updateStudent(){
                // if(this.is_busy) return;
                // this.is_busy = true;

                axios.put('/students/'+this.form.id, this.form)
                .then(res => {
                    Swal.fire(
                        'Updated!',
                        'Student Updated Successfully.',
                        'success'
                    )
                    //console.log(data);
                    this.$router.push({path: '/administration/student/'+ res.data.data.id });
                })
                .catch((err) => {
                    this.errors = err.response.data.errors;
                })
                .finally(() => {
                    this.is_busy = false;
                }); ;      
            },
        },
    }
</script>

<style>
    .wizard-header {
        display:none;
    }
</style>